.category_container{
    display: flex;
    width: 100%;
    padding: 3rem 0rem;
    align-items: center;
    align-self: stretch;
    background: linear-gradient(91deg, #33B9D6 1.34%, #1771F9 100%);
}
.head_main{
    display: flex;
    margin: auto;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}
.btn_container{
    display: flex;

align-items: center;
flex-wrap: wrap;
gap: var(--16, 16px);
}
.cat_btn{
    display: flex;

    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid rgba(214, 214, 214, 0.20);
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.25) 0%, rgba(247, 247, 247, 0.25) 100%);
    box-shadow: 1px 3px 20px 0px rgba(195, 195, 195, 0.12);
    backdrop-filter: blur(6px);
    color: var(--White, var(--Whiite-_00, #FFF));
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
}
.cat_skills{
    color: #00255E;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 140% */
    letter-spacing: -0.1px;
}