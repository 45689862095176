.custom-google-button {
  width: 100%;
  opacity: 0.9 !important;
  background-color: #4285f4;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 0;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
}

/* LinkedInButton.css */
.linkedin-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0077B5;
  color: white;
  border: none;
  padding: 10px 20px;
  width: 100%;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.linkedin-btn:hover {
  background-color: #005582;
}

.linkedin-icon {
  margin-right: 8px;
  font-size: 20px;
}