@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css"; 

body {
  @apply bg-white text-black;
}

.dark body {
  @apply bg-gray-900 text-white;
}

* {
  padding: 0px;
  margin: 0px;
  font-family: "Roboto";
  box-sizing: border-box;

  font-family: Roboto;

}

input, textarea, select {
  background-color: white;
  color: black;
}

input[type="date"] {
  appearance: auto !important;
  -webkit-appearance: auto !important;
  -moz-appearance: auto !important;
}

/* Universal checkbox styling */
input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  background-color: white;
  border: 2px solid #007bff; /* Blue border */
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

input[type="checkbox"]:checked {
  background-color: #007bff; /* Blue background when checked */
  border-color: #007bff;
}

input[type="checkbox"]:checked::after {
  content: '✔';
  color: white;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}



/* Custom CSS to hide scrollbar */
.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, Edge */
}

.hide-scrollbar {
  -ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
  scrollbar-width: none;  /* Hide scrollbar for Firefox */
}

.scroll-container {
  overflow-y: scroll; /* Enable vertical scrolling */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.scroll-container::-webkit-scrollbar {
  display: none;  /* Chrome, Safari, Opera */
}

/* for search icon mobile  */

.search-field {
  position: relative; 
}

.magnifying-glass-container {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f4f6; 
  border-radius: 50%; 
  cursor: pointer;
  transition: background-color 0.3s ease; 
  position: absolute; 
  right: 0; 
}

.magnifying-glass-container:hover {
  background-color: #e2e8f0; 
}

.search-field input {
  transition: all 0.5s ease-in-out;
  opacity: 1;
  border-radius: 20px;
}

/* Icon styles */
.magnifying-glass-container .FaSearch {
  color: #333;
  width: 24px;
  height: 24px;
  transition: color 0.3s ease;
}

.magnifying-glass-container:hover .FaSearch {
  color: #555;
}

@media (max-width: 768px) {
  .search-field input {
    font-size: 1rem;
  }
}


@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bounce-slow {
  0%, 100% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(0);
  }
}

@keyframes bounce-in {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.animate-fadeInDown {
  animation: fadeInDown 0.8s ease-out forwards;
}

.animate-bounce-slow {
  animation: bounce-slow 2s infinite;
}

.animate-bounce-in {
  animation: bounce-in 0.5s ease-out;
}

.animate-fadeIn {
  animation: fadeIn 0.8s ease-out forwards;
}

.delay-300 {
  animation-delay: 0.3s;
}

.delay-1000 {
  animation-delay: 1s;
}
@keyframes spin-slow {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.animate-spin-slow {
  animation: spin-slow 10s linear infinite;
}

/* about cards  */
@media (min-width: 768px) {
  @keyframes popInOut {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
  }

  .card-animation {
    animation: popInOut 2s infinite;
  }
}
 
/* icon whats app  */

.whatsapp-ico{
  fill: white;
  width: 45px;
  height: 45px;
  padding: 3px;
  background-color: #4dc247;
  border-radius: 50%;
  box-shadow: 2px 2px 6px rgba(0,0,0,0.4);
  /* box-shadow: 2px 2px 11px rgba(0,0,0,0.7); */
  position: fixed;
  bottom: 20px;
  right : 0px;
  z-index: 10;
}

.whatsapp-ico:hover{
  box-shadow: 2px 2px 11px rgba(0,0,0,0.7);
}
.custom-modal .ant-modal-body {
  max-height: 80vh; /* Adjust as needed */
  overflow-y: auto;
}



/* //get expert help */
@keyframes check {
  from {
    stroke-dashoffset: 26;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.animate-check path {
  stroke-dasharray: 26;
  stroke-dashoffset: 26;
  animation: check 0.4s ease-in-out forwards;
}




/* modal popup for getExpert help */


/* Define the animation for modal entrance */
@keyframes slideIn {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Define the animation for modal exit */
@keyframes slideOut {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-50px);
    opacity: 0;
  }
}

/* Define the animation for the checkmark icon */
@keyframes checkmark {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}


/* for recording details  */
.dark .bg-white {
  background-color: #1f2937; 
}
.dark .text-gray-700 {
  color: #f3f4f6; 
}




/* Apply the animation to the modal */
/* 
.animated-modal {
  animation: slideIn 0.3s ease-out;
}

.animated-modal.exiting {
  animation: slideOut 0.3s ease-out;
}



.custom-modal .ant-modal-content {
  border-radius: 16px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
  overflow: hidden;
}

.custom-modal .ant-modal-header {
  background: linear-gradient(135deg, #6a11cb, #2575fc);
  border-radius: 16px 16px 0 0;
  padding: 24px;
  text-align: center;
}

.custom-modal .ant-modal-title {
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
}

.custom-modal .ant-modal-body {
  font-size: 1.1rem;
  text-align: center;
  padding: 32px;
  color: #333;
}

.custom-modal .ant-modal-footer {
  text-align: center;
  border-top: none;
  padding: 16px;
}

.custom-modal .ant-btn {
  background: linear-gradient(135deg, #6a11cb, #2575fc);
  color: white;
  border: none;
  border-radius: 8px;
  padding: 10px 32px;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.3s ease;
}

.custom-modal .ant-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(106, 17, 203, 0.3);
}


.success-icon {
  font-size: 64px;
  color: #4caf50;
  animation: checkmark 0.6s ease-in-out;
}


.custom-modal:hover {
  transform: scale(1.02);
  transition: transform 0.3s ease;
} */