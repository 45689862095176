.navigate_header {
  display: flex;
  gap: 20px;
  align-items: center;
  height: 50px;
  margin: 0px 40px;
  position: relative;
}

.navigate_header p {
  color: black;
  cursor: pointer;
}

 .explore_btns_nav {
  background-color: #0966ed;
  padding: 12px 40px 12px 13px;
  color: #fff;
  border: none;
  border-radius: 4px;
  position: relative;
  font-size: 16px;
  margin-left: 35px;
} 
 .down_arrow {
  position: absolute;
  top: 9px;
  right: 0px;
  padding: 0px 10px 0px 10px;
} 
 .input_search__nav {
  padding: 0px 10px;
  border-radius: 4px;
  /* min-width: 270px; */
  border: 1px solid #c0c0c0;
  margin-left: 0px;
}  





  .img_search_icon{
    background-color: #0966ed;
    height: 40px;
    display: block;
    padding: 8px 10px;
    border-radius: 0px 4px 4px 0px;
} 
.btns_nav {
  display: flex;
  align-items: center;
  gap: 20px;
} 
.login_btn_navt{
  border: none;
  background-color: #fff;
  font-size: 16px;
  cursor: pointer;
  padding: 0px 20px;
  height: 40px;


}
.login_btn_nav{
    border: none;
    background-color: #fff;
    font-size: 16px;
    cursor: pointer;
    padding: 0px 20px;
} 
 .login_btn_nav:hover{
    color: #fff;
    background-color: #0966ed;
    height: 40px;
    border-radius: 4px;
}  /* Main User Profile Container */
.user-profile {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

/* Profile Icon and Name styling */
.profile-icon-name {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-radius: 20px;
  background-color: #f8f8f8;
  transition: background-color 0.3s ease;
}

.profile-icon-name:hover {
  background-color: #e6e6e6;
}

.profile-icon {
  color: #0073e6;
}

.user-name {
  font-weight: bold;
  color: #333;
}

/* Popup Menu Styling */
.popup-menu {
  position: absolute;
  top: 110%; /* Slightly below the icon */
  left: 0px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  padding: 10px 0;
  width: 160px;
  z-index: 10;
  transition: opacity 0.3s ease;
  opacity: 1;
}

/* Menu Items */
.popup-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.popup-menu li {
  padding: 6px;
  font-size: 14px;
  color: #333;
  text-align: center;
  transition: background-color 0.3s ease;
}

.popup-menu li:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

/* Logout Button */
.logout-btn {
  padding: 6px 20px;
  font-size: 14px;
  color: #ffffff;
  background-color: #d9534f;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.logout-btn:hover {
  background-color: #c9302c;
}



