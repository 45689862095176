.main_courses_div{
margin: auto;
width: 90%;
margin-top: 60px;
}
.for_listing_courses {
 display: flex;
 overflow-x: auto;
 margin: 20px 0px;
 color: black;
 gap: 15px;
 width: 100%;
  /* Hide scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
}
.for_listing_courses p{
    text-wrap: nowrap;
    cursor: pointer;
    padding-bottom: 8px;
}
.for_listing_courses .active{
    display: block;
    border-bottom: 4px solid #0966ed;
    
}

